












import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import UserProfileForm from '@/partials/forms/UserProfileForm.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    UserProfileForm
  }
})
export default class ProfileSettingsIndex extends Vue {
  @sessionModule.Getter state!: any
}
